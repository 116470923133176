import * as React from 'react'
import { Grid, Image, Segment } from 'semantic-ui-react'
import logo from '../images/osso2-01.png'
import logo2 from '../images/logo-01.png'
class Getting extends React.Component {
  constructor() {
    super()
    this.state = { color: 'red' }
  }
  render() {
    return (
      <>
        <Segment
          basic
          size={'huge'}
          style={{ margin: 0, padding: '5', backgroundColor: '#0b2e13' }}
        >
          <Grid>
            <Grid.Row>
              <Grid.Column
                computer={16}
                only="large screen"
                style={{ marginLeft: '2%' }}
              >
                <h4 style={{ color: '#ffff' }}>
                    รายงานข้อมูลการผลิต/นำเข้ายาและสัดส่วนการกระจายยาในช่องทางต่างๆ ของยาต้านจุลชีพชนิดยารับประทานทุกรูปแบบยา
                    <br />
                    ตั้งแต่วันที่ 1 ม.ค. 63 ถึง 31 ธ.ค. 63 (ปี พ.ศ. 2563)
                </h4>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
      </>
    )
  }
}
export default Getting
