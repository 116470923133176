import * as React from 'react'
import {Button, Grid, Segment} from 'semantic-ui-react'
class Footer extends React.Component {
  constructor() {
    super()
    this.state = { color: 'red' }
  }
  render() {
    return (
      <>
        <Segment
          basic
          size={'huge'}
          style={{
            margin: 0,
            padding: '10',
          }}
        >
          <Grid>
            <Grid.Row>
              <Grid.Column width={10}  style={{ padding: '2%' }}>
                <p style={{fontSize:'16px'}}><strong>กลุ่มนโยบายแห่งชาติด้านยา กองยา  สำนักงานคณะกรรมการอาหารและยา</strong><br/>
                    <strong>โทร:</strong> 0 2590 7155 , 06 1819 8990 โทรสาร: 0 2590 7341<br/>
                    <strong>อีเมล์:</strong> drugreclass.fda@gmail.com<br/>
                    <strong>ผู้ประสานงาน:</strong> ภญ.ปานตา เทือกสุบรรณ ประสานงานแทน ภญ.อัญชลี จิตรักนที หัวหน้ากลุ่มนโยบายฯ<br/>
                </p>
              </Grid.Column>
              <Grid.Column width={5} textAlign={'center'} only="large screen">
                  <br/>
                  <a href={"https://ossc.sgp1.cdn.digitaloceanspaces.com/%E0%B8%84%E0%B8%B9%E0%B9%88%E0%B8%A1%E0%B8%B7%E0%B8%AD%E0%B8%A3%E0%B8%B0%E0%B8%9A%E0%B8%9A%E0%B8%95%E0%B8%B4%E0%B8%94%E0%B8%95%E0%B8%B2%E0%B8%A1%E0%B8%81%E0%B8%A3%E0%B8%B0%E0%B8%88%E0%B8%B2%E0%B8%A2%E0%B8%A2%E0%B8%B2%E0%B8%95%E0%B9%89%E0%B8%B2%E0%B8%99%E0%B8%88%E0%B8%B8%E0%B8%A5%E0%B8%8A%E0%B8%B5%E0%B8%9E_final.pdf"}>
                      <Button primary>คู่มือการใช้งาน</Button>
                  </a>
                  <br/>
                  <p style={{fontSize:'16px'}}>พบปัญหาการใช้งานระบบ ติดต่อโทร: 02-821-5509</p>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
      </>
    )
  }
}
export default Footer
