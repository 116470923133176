import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { store, persistor } from './configureStore'
import { PersistGate } from 'redux-persist/integration/react'
import { usePromiseTracker } from 'react-promise-tracker'
import 'bootstrap/dist/css/bootstrap.min.css'

const LoadingIndicator = (props) => {
  const { promiseInProgress } = usePromiseTracker()
  return promiseInProgress
}

const AppWithRouter = () => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        <App />
        <LoadingIndicator />
      </BrowserRouter>
    </PersistGate>
  </Provider>
)
ReactDOM.render(<AppWithRouter />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
