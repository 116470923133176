import Action from '../action'

const initState = {
  isToken: '',
  isLogin: false,
  company: '',
  tel: '',
  email: '',
  firsname: '',
  lastname: '',
  position: '',
  username: '',
}
const LoginReducer = (state = initState, action) => {
  switch (action.type) {
    case Action.LoginAction:
      return {
        ...state,
        isToken: action.Token,
        username: action.Username,
        company: action.company,
        tel: action.tel,
        email: action.email,
        firsname: action.firsname,
        lastname: action.lastname,
        position: action.position,
        isLogin: true,
      }
    case Action.LogoutAction:
      return {
        ...state,
        username: '',
        isToken: '',
        isLogin: false,
      }
    default:
      return state
  }
}
export default LoginReducer
