import './App.css'
import { Redirect, Route, Switch } from 'react-router-dom'
import React from 'react'
import 'semantic-ui-css/semantic.min.css'
import Navbar from './pages/Navbar'
import Footer from './pages/footer'
import { Spinner } from 'react-bootstrap'
const Main = React.lazy(() => import('./pages/main'))
const login = React.lazy(() => import('./pages/login'))
const Personal = React.lazy(() => import('./pages/personal'))
const Detail = React.lazy(() => import('./pages/detail'))
const More = React.lazy(() => import('./pages/more'))
const loading = () => (
  <div className="animated fadeIn pt-3 text-center">
    <Spinner animation="grow" variant="success" />
  </div>
)
const Page404 = React.lazy(() => import('./pages/Page404'))
const Logout = React.lazy(() => import('./pages/logout'))

function App() {
  return (
    <>
      <Navbar />
      <React.Suspense fallback={loading()}>
        <Switch>
          <Route exact path="/" render={() => <Redirect to="/login" />} />
          <Route exact path="/main" component={Main} />
          <Route exact path="/login" component={login} />
          <Route exact path="/personal" component={Personal} />
          <Route exact path="/detail/:id" component={Detail} />
          <Route exact path="/more/:id" component={More} />
          <Route exact path="/logout" component={Logout} />
          <Route exact path="/404" component={Page404} />
          <Route render={() => <Redirect to="/404" />} />
        </Switch>
      </React.Suspense>
      <Footer/>
    </>
  )
}

export default App
